.dashboard-container {
  max-width: 72rem;
  margin: 0 auto;
  padding: 1rem;
}

.header {
  background: linear-gradient(to right, #EFF6FF, #EEF2FF);
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}

.header-title {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0.5rem;
  color: #1E3A8A;
}

.header-subtitle {
  text-align: center;
  color: #1D4ED8;
  font-weight: 500;
}

.chart-container {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.chart-header {
  padding: 1rem;
  border-bottom: 1px solid #E5E7EB;
}

.chart-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.chart-content {
  padding: 1.5rem;
}

.chart-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
}

@media (min-width: 768px) {
  .chart-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.chart-height-large {
  height: 20rem;
}

.chart-height-medium {
  height: 16rem;
}

/* Chart Colors */
:root {
  --chart-color-1: #3b82f6; /* Primary - Engagement */
  --chart-color-2: #10b981; /* Secondary - CTR */
  --chart-color-3: #6366f1; /* Tertiary - Intent */
  --chart-color-4: #f59e0b; /* Quaternary - Wellness */
  --chart-color-5: #ef4444; /* Quinary - Medical */
}

/* Target Group Colors */
.group-a { color: var(--chart-color-1); }
.group-b { color: var(--chart-color-2); }
.group-c { color: var(--chart-color-3); }
.group-d { color: var(--chart-color-4); }
.group-e { color: var(--chart-color-5); }

/* Custom Tooltip Styles */
.custom-tooltip {
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #E5E7EB;
}

.tooltip-label {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.tooltip-value {
  margin-bottom: 0.25rem;
}

/* Chart Grid Styles */
.metrics-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin: 1rem 0;
}

@media (min-width: 768px) {
  .metrics-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
