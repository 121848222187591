/* Base Market Analysis Container Styles */
.market-analysis {
    padding: 20px;
    background-color: var(--color-surface);
    border-radius: 8px;
}

/* Grid Layout for Market Sections */
.market-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

/* Individual Market Section Styling */
.market-section {
    background-color: var(--color-surface-secondary);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px var(--shadow-color);
}

/* Section Header Styling */
.market-section h3 {
    color: var(--color-primary);
    margin: 0 0 15px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border-color);
    font-size: 1.1rem;
}

/* Market Content Layout */
.market-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Market Value Display */
.market-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.value {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--color-primary);
}

/* CAGR Badge Styling */
.cagr {
    font-size: 0.9rem;
    color: var(--color-success);
    background-color: var(--color-success-light);
    padding: 4px 8px;
    border-radius: 4px;
}

/* Market Details Container */
.market-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Industry Text Styling */
.industry {
    font-weight: 500;
    color: var(--color-text-primary);
}

/* Description Text Styling */
.description {
    font-size: 0.9rem;
    color: var(--color-text-secondary);
}

/* Source Information Styling */
.source {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    color: var(--color-text-tertiary);
    margin-top: 10px;
}

/* Responsive Grid Adjustments */
@media (max-width: 1024px) {
    .market-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .market-grid {
        grid-template-columns: 1fr;
    }
}

/* Main Container Layout */
.tam-sam-som-container {
    padding: 20px;
    background-color: var(--color-surface);
    border-radius: 8px;
}

/* Content Layout with Cards and Visualization */
.visualization-content {
    display: flex;
    flex-direction: column-reverse; /* Renders bubble-visualization first */
    gap: 2rem;
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
}

/* Market Cards Container */
.market-cards {
    display: grid;
    gap: 1.5rem;
    width: 100%;
}

/* Individual Market Card Styling */
.market-card {
    background-color: var(--card-bg);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px var(--shadow-color);
    transition: all 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

/* Active Card State */
.market-card.active {
    transform: translateX(10px);
    box-shadow: 0 4px 8px var(--shadow-color);
}

/* Card Header Layout */
.market-card-header {
    order: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
}

/* Market Indicator Dot */
.market-indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--market-color);
}

/* Card Title Styling */
.market-card h3 {
    margin: 0;
    color: var(--market-color);
    font-size: 16px;
    font-weight: 600;
}

/* Industry Section Styling */
.market-industry {
    order: 2;
    margin: 2px 0 0 0;
    font-size: 15px;
    font-weight: 600;
    color: var(--color-text);
    cursor: help;
    transition: opacity 0.2s ease;
}

/* Card Value Display */
.market-value {
    order: 4;
    margin: 4px 0;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.5px;
}

/* Description Text Styling */
.market-description {
    order: 3;
    margin: 2px 0 4px 0;
    font-size: 12px;
    color: var(--color-text-secondary);
    line-height: 1.3;
    cursor: help;
}

/* Card Footer Layout */
.market-footer {
    order: 5;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 4px;
    margin: 0 -10px;
    padding: 4px 10px 0;
    background-color: rgba(0, 0, 0, 0.02);
}

/* Source Information Styling */
.market-source {
    font-size: 10px;
    color: var(--color-text-secondary);
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding-left: 2px;
}

/* CAGR Box Styling */
.market-cagr {
    background-color: rgba(0,0,0,0.1);
    padding: 3px 8px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    margin-right: 2px;
}

/* Bubble Visualization Container */
.bubble-visualization {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

/* Add media query for larger screens */
@media (min-width: 1024px) {
  .visualization-content {
    flex-direction: row;
    align-items: center;
  }

  .market-cards {
    flex: 1;
  }

  .bubble-visualization {
    flex: 1;
  }
}

/* SVG Styling */
.bubble-visualization svg {
    width: 100%;
    height: 100%;
}

/* Market Bubble Styling */
.bubble-visualization circle {
    cursor: pointer;
    transition: all 0.3s ease;
}

.bubble-visualization circle:hover {
    opacity: 1;
    filter: brightness(1.1);
}

/* Bubble Label Styling */
.bubble-visualization text {
    text-shadow: 0 1px 2px rgba(0,0,0,0.3);
    pointer-events: none;
}

/* Toast Notification Styling */
.cursor-toast {
    position: fixed;
    background-color: rgba(33, 33, 33, 0.95);
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 13px;
    max-width: 300px;
    line-height: 1.4;
    pointer-events: none;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    animation: fadeIn 0.2s ease-in-out;
}

/* Toast Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(0, -45%);
    }
    to {
        opacity: 1;
        transform: translate(0, -50%);
    }
}

/* Title Section Styling */
.market-title-section {
    text-align: center;
    margin-bottom: 20px;
}

.market-main-title {
    font-size: 28px;
    font-weight: 600;
    color: var(--color-text-primary);
    margin: 0;
    padding: 0;
}

.market-subtitle {
    font-size: 16px;
    color: var(--color-text-secondary);
    margin: 4px 0 0 0;
}