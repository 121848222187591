/* Common styles for all viewers */
.target-group h3,
.frame-header h4,
.scene-header h4 {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  color: var(--color-text);
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  margin: 0;
  cursor: pointer;
  border-radius: 4px;
}

.target-group h3:hover,
.frame-header h4:hover,
.scene-header h4:hover {
  background-color: var(--color-background-hover);
}

/* Standardized collapse indicator styles */
.collapse-indicator {
  display: inline-block;
  width: 20px;
  text-align: center;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.collapse-indicator.expanded {
  transform: rotate(90deg);
}

/* Container styles - make sure ALL headers are clickable */
.target-group h3,
.frame-header h4,
.scene-header h4,
.frame-header,
.scene-header {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  margin: 0;
  cursor: pointer;
  border-radius: 4px;
}

/* Hover effect for all collapsible headers */
.target-group h3:hover,
.frame-header:hover,
.scene-header:hover,
.frame-header h4:hover,
.scene-header h4:hover {
  background-color: var(--color-background-hover);
}

.group-info-grid {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  margin-left: 20px;
  gap: 4px
}

.screen-info-grid {
  display: grid;
  grid-template-columns: 1fr 5fr;
  margin-left: 20px;
  gap: 4px
}

.screen-detail-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
}

.screen-detail-grid > :nth-child(3) {
  grid-column: span 2;
}

.d-none {
  display: none;
}