.glow_03 {

  display: flex;
  flex-direction: column;
  gap: 5px;

  .group-info-grid {
    margin-left: unset;
  }
  .action-bar-containter {
    padding: 10px;
    gap: 5px;
    background-color: var(--color-surface);
    box-shadow: 0 2px 4px var(--shadow-color);
    border: 1px solid var(--card-border);    
    border-radius: 8px;
    min-width: 185px;
  }
  /* Add these new styles for ProcessViewer2 */
  .scenes-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 15px;
  }
  .group-card,
  .scene-card, 
  .frame-card {
    background-color: var(--color-surface);
    box-shadow: 0 2px 4px var(--shadow-color);
    border: 1px solid var(--card-border);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    min-height: 350px;
    max-height: 400px;
    margin-top: unset;
    padding: unset;
    width: 100%;
    &.selected {
      min-width: 250px;
      max-width: 40%;
      border-color: var(--button-hover);
    }
    &:hover {
      transform: scale(1.02);
      box-shadow: 0 0 8px var(--button-hover);
      border-color: var(--button-hover);
    }
    &.hover {
      /* transform: scale(1.02); */
      box-shadow: 0 0 8px var(--button-hover);
      border-color: var(--button-hover);
    }
  }

  .group-card {
    max-width: 400px;
  }

  .scene-card h3 {
    margin: 0;
    padding: 10px 0;
    color: var(--color-text);
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .group-image,
  .scene-image, 
  .frame-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .scene-image {
    width: 100%;
    max-width: unset;
  }

  .group-image img, .scene-image img,.frame-image img {
    width: 100%;
    height: inherit;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
    /* transition: transform 0.5s ease; */
  }


  .scene-image .MuiButton-root {
    margin: 10px;
  }

  /* Add these new styles for ProcessViewer3 */
  .group-content,
  .scene-content,
  .frame-content {
    display: flex;
    flex-direction: row;
    gap: 0;
    margin-top: unset;
    padding-left: unset;
    justify-content: space-evenly;
    border-radius: 8px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .group-content {
    transition: 0.3s;
    gap: 5px;   
    
    &:hover, &.hover {
      /* transform: scale(1.02); */
      box-shadow: 0 0 8px var(--button-hover);
      border-color: var(--button-hover);
    }
  }

  .scene-content,
  .frame-content {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }

  .scene-wrapper, .frame-wrapper {
    display: flex;
    transition: 0.5s;
    position: relative;
    border-radius: 8px;
    gap: 5px;
    padding: 0 2.5px;
    &:hover, &.hover {
      /* transform: scale(1.02); */
      box-shadow: 0 0 8px var(--button-hover);
      border-color: var(--button-hover);
    }
    &.selected {
      box-shadow: 0 0 8px var(--button-hover);
      border-color: var(--button-hover);
    }
  }

  .scene-content-other-nodes {
    display: flex;
    width: 30%;
  }

  .frame-item,
  .scene-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    :is(p) {
      margin: 0;
      padding-left: 10px;
      border-left: 2px solid var(--color-primary);
      font-size: 0.9rem;
    }
  }

  .group-detail,
  .frame-details,
  .scene-details {
    background-color: var(--color-background);
    padding: 15px;
    border-radius: 8px;
    border: 1px solid var(--card-border);
    margin-top: unset;
    margin-bottom: unset;
    max-height: 400px;
    overflow: hidden;
    flex-grow: 2;
    width: 100%;
    :is(strong) {
      color: var(--color-primary) !important;
      font-size: 0.9rem;
    }
    :is(h4){
      margin: 0;
      padding: 10px 0;
      color: var(--color-primary);
      text-align: center;
      font-weight: 700;
    }
  }

  .frames-container {
    margin-top: 15px;
  }

  .frames-container h5 {
    color: var(--color-primary);
    margin: 0 0 10px 0;
  }
}